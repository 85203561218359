<template>
  <div class="cgu max-w-lg mr-auto ml-auto text-justify">
    <JayaTitle classType="page-title" titleName="Conditions générales d'utilisation"></JayaTitle>
    <b-card class="mt-1">
      <h2 class="mt-0">1. Pr&eacute;sentation de l&rsquo;Application Hortilio et Informations l&eacute;gales</h2>
      <p>En vertu de l'article 6 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique,
        il est pr&eacute;cis&eacute; aux utilisateurs de l&rsquo;Application Hortilio (ci-apr&egrave;s &laquo;&nbsp;l&rsquo;<strong>Application</strong>&nbsp;&raquo;)
        l'identit&eacute; des diff&eacute;rents intervenants dans le cadre de sa r&eacute;alisation et de son suivi :
      </p>
      <p><strong>Propri&eacute;taires</strong>&nbsp;: Copropri&eacute;taire 1&nbsp;: Soci&eacute;t&eacute; CDC
        Biodiversit&eacute;, Soci&eacute;t&eacute; par Actions Simplifi&eacute;e Unipersonnelle, dont le si&egrave;ge
        social est situ&eacute; au 102 rue R&eacute;aumur 75002, Paris, immatricul&eacute;e au registre du commerce de
        Paris au num&eacute;ro RCS PARIS 501 639 587</p>
      <p>Copropri&eacute;taire 2&nbsp;: Jaya SAS (ci-apr&egrave;s &laquo;&nbsp;<strong>Jaya</strong>&nbsp;&raquo;)
        &ndash;, soci&eacute;t&eacute; &agrave; responsabilit&eacute; limit&eacute;e, dont le si&egrave;ge social est
        situ&eacute; au 23 rue Jean Baldassini 69007, Lyon, immatricul&eacute;e au registre du commerce de Lyon au num&eacute;ro
        8080 826&nbsp;235 R.C.S. Lyon</p>
      <p><strong>Cr&eacute;ateur</strong>&nbsp;: Cr&eacute;ation partag&eacute; entre Jaya SAS et la soci&eacute;t&eacute;
        CDC Biodiversit&eacute;</p>
      <p><strong>Responsable de publication</strong>&nbsp;: Jaya</p>
      <p><strong>Webmaster </strong>: Jaya &ndash; contact@jaya-garden.fr</p>
      <p><strong>H&eacute;bergeur</strong>&nbsp;:&nbsp;<a @click="$openLinkJaya('https://www.ovh.com/fr/')" class="text-primary">www.ovh.com</a>&nbsp;&ndash; 2
        rue Kellermann - 59100 Roubaix - France</p>
      <p><strong>Cr&eacute;dits</strong>&nbsp;: Jaya</p>
      <p>&nbsp;</p>
      <h2>2. Conditions g&eacute;n&eacute;rales d&rsquo;utilisation de l&rsquo;Application et des services propos&eacute;s</h2>
      <p>L&rsquo;utilisation de l&rsquo;Application implique l&rsquo;acceptation pleine et enti&egrave;re des conditions
        g&eacute;n&eacute;rales d&rsquo;utilisation ci-apr&egrave;s d&eacute;crites. Ces conditions d&rsquo;utilisation
        sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es ou compl&eacute;t&eacute;es &agrave; tout moment, les
        utilisateurs de l&rsquo;Application sont donc invit&eacute;s &agrave; les consulter de mani&egrave;re r&eacute;guli&egrave;re.</p>
      <p>Cette Application est normalement accessible &agrave; tout moment aux utilisateurs, &agrave; l&rsquo;exception
        des suspensions et interruptions occasionn&eacute;es par la gestion de l&rsquo;Application d&eacute;crites
        &agrave; l&rsquo;article 3 des pr&eacute;sentes.</p>
      <p>L&rsquo;Application est mise &agrave; jour r&eacute;guli&egrave;rement par le cr&eacute;ateur.</p>
      <p>De la m&ecirc;me fa&ccedil;on, les mentions l&eacute;gales peuvent &ecirc;tre modifi&eacute;es &agrave; tout
        moment. Elles s&rsquo;imposent n&eacute;anmoins &agrave; l&rsquo;utilisateur qui est invit&eacute; &agrave; s&rsquo;y
        r&eacute;f&eacute;rer le plus souvent possible afin d&rsquo;en prendre connaissance.</p>
      <p>&nbsp;</p>
      <h2>3. Gestion de l&rsquo;Application</h2>
      <p>Pour la bonne gestion de l&rsquo;Application, Jaya pourra &agrave; tout moment :</p>
      <ul>
        <li>Suspendre, interrompre ou limiter l'acc&egrave;s &agrave; tout ou partie de l&rsquo;Application, r&eacute;server
          l'acc&egrave;s &agrave; l&rsquo;Application, ou &agrave; certaines parties de l&rsquo;Application, &agrave;
          une cat&eacute;gorie d&eacute;termin&eacute;e d'internaute,
        </li>
        <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
          nationales ou internationales, ou avec les r&egrave;gles de la N&eacute;tiquette,
        </li>
        <li>Suspendre l&rsquo;Application afin de proc&eacute;der &agrave; des mises &agrave; jour.</li>
      </ul>
      <p>&nbsp;</p>
      <h2>4. Description des services fournis</h2>
      <p>Hortilio est une application num&eacute;rique dont l&rsquo;objectif est d&rsquo;accompagner et de sensibiliser
        les habitants &agrave; la biodiversit&eacute; et au v&eacute;g&eacute;tal en ville.</p>
      <p>Cette application est un outil d&rsquo;aide au maintien et &agrave; l&rsquo;am&eacute;lioration de la
        biodiversit&eacute; urbaine, il permet d&rsquo;apprendre les bonnes pratiques en mati&egrave;re de v&eacute;g&eacute;talisation
        et de biodiversit&eacute; pour &ecirc;tre acteur de la biodiversit&eacute; sur son territoire. Cette application
        est aussi un outil de conseil &agrave; la gestion d&rsquo;un jardin et &agrave; l&rsquo;horticulture.</p>
      <p>Jaya s&rsquo;efforce de fournir sur L&rsquo;Application des informations aussi pr&eacute;cises que possible.
        Toutefois, Jaya ne pourra &ecirc;tre tenue responsable des omissions, des inexactitudes et des carences dans la
        mise &agrave; jour, qu&rsquo;elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces
        informations.</p>
      <p>Toutes les informations indiqu&eacute;es sur l&rsquo;Application sont donn&eacute;es &agrave; titre indicatif,
        et sont susceptibles d&rsquo;&eacute;voluer. Par ailleurs, les renseignements figurant sur l&rsquo;Application
        ne sont pas exhaustifs&nbsp;; ils sont donn&eacute;s sous r&eacute;serve de modifications ayant &eacute;t&eacute;
        apport&eacute;es depuis leur mise en ligne.</p>
      <p>&nbsp;</p>
      <h2>5. Limitations contractuelles sur les donn&eacute;es techniques</h2>
      <p>L&rsquo;Application utilise les technologies web.</p>
      <p>Jaya ne pourra &ecirc;tre tenue responsable de dommages mat&eacute;riels li&eacute;s &agrave; l&rsquo;utilisation
        de l&rsquo;Application. De plus, l&rsquo;utilisateur de l&rsquo;Application s&rsquo;engage &agrave; acc&eacute;der
        &agrave; celle-ci en utilisant un mat&eacute;riel r&eacute;cent, ne contenant pas de virus et avec un navigateur
        de derni&egrave;re g&eacute;n&eacute;ration mis-&agrave;-jour.</p>
      <p>&nbsp;</p>
      <h2>6. Propri&eacute;t&eacute; intellectuelle et contrefa&ccedil;ons</h2>
      <p>Jaya est propri&eacute;taire des droits de propri&eacute;t&eacute; intellectuelle ou d&eacute;tient les droits
        d&rsquo;usage sur tous les &eacute;l&eacute;ments accessibles sur l&rsquo;Application, notamment les textes,
        images, graphismes, logo, ic&ocirc;nes, sons, logiciels.</p>
      <p>Toute reproduction, repr&eacute;sentation, modification, publication, adaptation de tout ou partie des &eacute;l&eacute;ments
        de l&rsquo;Application, quel que soit le moyen ou le proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf
        autorisation &eacute;crite pr&eacute;alable de Jaya.</p>
      <p>Toute exploitation non autoris&eacute;e de l&rsquo;Application ou de l&rsquo;un quelconque des &eacute;l&eacute;ments
        qu&rsquo;il contient sera consid&eacute;r&eacute;e comme constitutive d&rsquo;une contrefa&ccedil;on et
        poursuivie conform&eacute;ment aux dispositions des articles L.335-2 et suivants du Code de Propri&eacute;t&eacute;
        Intellectuelle.</p>
      <p>&nbsp;</p>
      <h2>7. Limitations de responsabilit&eacute;</h2>
      <p>La responsabilit&eacute; de Jaya ne peut &ecirc;tre engag&eacute;e en cas de d&eacute;faillance, panne,
        difficult&eacute; ou interruption de fonctionnement, emp&ecirc;chant l'acc&egrave;s &agrave; cette Application
        ou &agrave; une de ses fonctionnalit&eacute;s.</p>
      <p>Jaya ne pourra &ecirc;tre tenue responsable des dommages directs et indirects caus&eacute;s au mat&eacute;riel
        de l&rsquo;utilisateur, lors de l&rsquo;acc&egrave;s &agrave; cette Application, et r&eacute;sultant soit de l&rsquo;utilisation
        d&rsquo;un mat&eacute;riel ne r&eacute;pondant pas aux sp&eacute;cifications indiqu&eacute;es au point 4, soit
        de l&rsquo;apparition d&rsquo;un bug ou d&rsquo;une incompatibilit&eacute;.</p>
      <p>Jaya ne pourra &eacute;galement &ecirc;tre tenue responsable des dommages indirects (tels par exemple qu&rsquo;une
        perte de march&eacute; ou perte d&rsquo;une chance) cons&eacute;cutifs &agrave; l&rsquo;utilisation de l&rsquo;Application.</p>
      <p>Des espaces interactifs (possibilit&eacute; de poser des questions dans l&rsquo;espace contact) sont &agrave;
        la disposition des utilisateurs. Jaya se r&eacute;serve le droit de supprimer, sans mise en demeure pr&eacute;alable,
        tout contenu d&eacute;pos&eacute; dans cet espace qui contreviendrait &agrave; la l&eacute;gislation applicable
        en France, en particulier aux dispositions relatives &agrave; la protection des donn&eacute;es. Le cas &eacute;ch&eacute;ant,
        Jaya se r&eacute;serve &eacute;galement la possibilit&eacute; de mettre en cause la responsabilit&eacute; civile
        et/ou p&eacute;nale de l&rsquo;utilisateur, notamment en cas de message &agrave; caract&egrave;re raciste,
        injurieux, diffamant, ou pornographique, quel que soit le support utilis&eacute; (texte,
        photographie&hellip;).</p>
      <p>&nbsp;</p>
      <h2>8. Gestion et protection des donn&eacute;es &agrave; caract&egrave;re personnel des internautes</h2>
      <h3>8.1. Collecte des donn&eacute;es personnelles</h3>
      <p>A l'occasion de l'utilisation de l&rsquo;Application , les donn&eacute;es &agrave; caract&egrave;re personnel
        qui sont collect&eacute;es sont les suivantes&nbsp;:</p>
      <ul>
        <li>&Agrave; l&rsquo;occasion de l&rsquo;ouverture d&rsquo;un compte utilisateur&nbsp;: nom, pr&eacute;nom,
          adresse, adresse &eacute;lectronique et date de naissance.
        </li>
        <li>&Agrave; chaque connexion&nbsp;: donn&eacute;es de connexion, localisation, l'URL des liens par l'interm&eacute;diaire
          desquels l'utilisateur a acc&eacute;d&eacute; &agrave; cette Application , le fournisseur d'acc&egrave;s de
          l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h3>8.2. Utilisation des donn&eacute;es personnelles</h3>
      <p>Jaya ne collecte des informations personnelles relatives &agrave; l'utilisateur que pour le besoin de certains
        services propos&eacute;s par l&rsquo;Application. L'utilisateur fournit ces informations en toute connaissance
        de cause, notamment lorsqu'il proc&egrave;de par lui-m&ecirc;me &agrave; leur saisie. Il est alors pr&eacute;cis&eacute;
        &agrave; l'utilisateur de l&rsquo;Application l&rsquo;obligation ou non de fournir ces informations.</p>
      <p>Les utilisations des donn&eacute;es personnelles sont notamment les suivantes&nbsp;:</p>
      <ul>
        <li>L&rsquo;utilisateur cr&eacute;e ou modifie son compte client ou compte utilisateur dans son espace d&eacute;di&eacute;
          sur l&rsquo;Application,
        </li>
        <li>L&rsquo;utilisateur navigue sur l&rsquo;Application, consulte des produits et utilise les services de l&rsquo;Application,</li>
        <li>L&rsquo;utilisateur contacte le service client de l&rsquo;Application,</li>
        <li>La gestion du fonctionnement et l&rsquo;optimisation de l&rsquo;Application,</li>
        <li>La personnalisation des services en affichant des publicit&eacute;s en fonction de l'historique de
          navigation de l'utilisateur, selon ses pr&eacute;f&eacute;rences,
        </li>
        <li>La pr&eacute;vention et d&eacute;tection des fraudes, malwares (softwares ou logiciels malveillants) et
          gestion des incidents de s&eacute;curit&eacute;,
        </li>
        <li>L&rsquo;envoi d'informations commerciales et publicitaires, en fonction des pr&eacute;f&eacute;rences de
          l'utilisateur.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h3>8.3. Partage des donn&eacute;es personnelles avec des tiers</h3>
      <p>Aucune information personnelle de l'utilisateur de l&rsquo;Application n'est publi&eacute;e &agrave; l'insu de
        l'utilisateur, &eacute;chang&eacute;e, transf&eacute;r&eacute;e, c&eacute;d&eacute;e ou vendue sur un support
        quelconque &agrave; des tiers. Seule l'hypoth&egrave;se du rachat de Jaya et de ses droits permettrait la
        transmission des dites informations &agrave; l'&eacute;ventuel acqu&eacute;reur qui serait &agrave; son tour
        tenu de la m&ecirc;me obligation de conservation et de modification des donn&eacute;es vis &agrave; vis de
        l'utilisateur de l&rsquo;Application.</p>
      <p>Vos donn&eacute;es personnelles pourront &ecirc;tre communiqu&eacute;es en Application d&rsquo;une loi, d&rsquo;un
        r&egrave;glement, d&rsquo;une d&eacute;cision d&rsquo;une autorit&eacute; r&eacute;glementaire ou judiciaire et
        le cas &eacute;ch&eacute;ant, afin de pr&eacute;server les droits et int&eacute;r&ecirc;ts de Jaya.</p>
      <p>&nbsp;</p>
      <h3>8.4. S&eacute;curit&eacute; et confidentialit&eacute;</h3>
      <p>Jaya met en &oelig;uvre des mesures organisationnelles, techniques, logicielles et physiques en mati&egrave;re
        de s&eacute;curit&eacute; du num&eacute;rique pour prot&eacute;ger les donn&eacute;es personnelles contre les
        alt&eacute;rations, destructions et acc&egrave;s non autoris&eacute;s. Toutefois, il est &agrave; signaler
        qu'internet n'est pas un environnement compl&egrave;tement s&eacute;curis&eacute; et que L&rsquo;Application ne
        peut pas garantir la s&eacute;curit&eacute; de la transmission ou du stockage des informations sur internet.</p>
      <p>&nbsp;</p>
      <h3>8.5. Mise en &oelig;uvre des droits des utilisateurs</h3>
      <p>Conform&eacute;ment &agrave; la r&eacute;glementation applicable, tout utilisateur dispose d&rsquo;un droit d&rsquo;acc&egrave;s
        &agrave; ses donn&eacute;es personnelles, de rectification et de suppression des donn&eacute;es personnelles le
        concernant, en effectuant sa demande &eacute;crite et sign&eacute;e &agrave; l&rsquo;adresse &eacute;lectronique
        suivante&nbsp;:&nbsp;<u>contact@jaya-garden.fr</u>, accompagn&eacute;e d&rsquo;une copie du titre d&rsquo;identit&eacute;
        avec signature du titulaire de la pi&egrave;ce, en pr&eacute;cisant l&rsquo;adresse &agrave; laquelle la r&eacute;ponse
        doit &ecirc;tre envoy&eacute;e.</p>
      <p>&nbsp;</p>
      <h2>9. Liens hypertextes et cookies</h2>
      <p>L&rsquo;Application contient un certain nombre de liens hypertextes vers d&rsquo;autres Applications et Sites
        Internet mis en place avec l&rsquo;autorisation de Jaya. Cependant, Jaya n&rsquo;a pas la possibilit&eacute; de
        v&eacute;rifier le contenu des Applications et Sites ainsi visit&eacute;s, et n&rsquo;assumera en cons&eacute;quence
        aucune responsabilit&eacute; de ce fait.</p>
      <p>&nbsp;</p>
      <h2>10. Droit applicable et attribution de juridiction</h2>
      <p>Tout litige en relation avec l&rsquo;utilisation de l&rsquo;Application est soumis au droit fran&ccedil;ais. Il
        est fait attribution exclusive de juridiction aux tribunaux comp&eacute;tents de Paris.</p>
      <p>&nbsp;</p>
      <h2>11. Les principales lois concern&eacute;es</h2>
      <p>Loi n&deg; 78-17 du 6 janvier 1978, notamment modifi&eacute;e par la loi n&deg; 2004-801 du 6 ao&ucirc;t 2004
        relative &agrave; l'informatique, aux fichiers et aux libert&eacute;s.</p>
      <p>Loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique.</p>
      <p>&nbsp;</p>
      <h2>12. Contribution des utilisateurs</h2>
      <p>Jaya propose &agrave; tous les utilisateurs de participer &agrave; un forum gratuitement. En postant une
        contribution, l'utilisateur reconna&icirc;t que celle-ci sera publi&eacute;e sur l&rsquo;application sous sa
        responsabilit&eacute;. La contribution de l'utilisateur ne doit pas :</p>
      <ul>
        <li>porter atteinte ou &ecirc;tre contraire &agrave; l'ordre public, aux bonnes m&oelig;urs ou pouvoir heurter
          la sensibilit&eacute; des mineurs ;
        </li>
        <li>porter atteinte de quelque mani&egrave;re que ce soit aux droits &agrave; la r&eacute;putation, &agrave; la
          vie priv&eacute;e, &agrave; l'image d'un tiers ;
        </li>
        <li>&ecirc;tre d&eacute;nigrant, diffamatoire, porter atteinte &agrave; l'image, &agrave; la r&eacute;putation
          d'une marque ou d'une quelconque personne physique ou morale, de quelque mani&egrave;re que ce soit ;
        </li>
        <li>pr&eacute;senter un caract&egrave;re pornographique ou p&eacute;dophile ;</li>
        <li>porter atteinte &agrave; la s&eacute;curit&eacute; ou &agrave; l'int&eacute;grit&eacute; d'un Etat ou d'un
          territoire, quel qu'il soit ;
        </li>
        <li>permettre &agrave; des tiers de se procurer des logiciels pirat&eacute;s, des num&eacute;ros de s&eacute;rie
          de logiciels ou tout logiciel pouvant nuire ou porter atteinte, de quelque mani&egrave;re que ce soit, aux
          droits ou aux biens des tiers ;
        </li>
        <li>porter atteinte aux droits de propri&eacute;t&eacute; intellectuelle de quelque personne que ce soit ;</li>
        <li>inciter &agrave; la haine, &agrave; la violence, au suicide, au racisme, &agrave; l'antis&eacute;mitisme,
          &agrave; la x&eacute;nophobie, &agrave; l'homophobie, faire l'apologie des crimes de guerre ou des crimes
          contre l'humanit&eacute; ;
        </li>
        <li>inciter &agrave; commettre un crime, un d&eacute;lit ou un acte de terrorisme ;</li>
        <li>inciter &agrave; la discrimination d'une personne ou d'un groupe de personnes en raison de son appartenance
          &agrave; une ethnie, &agrave; une religion, &agrave; une race, ou du fait de son orientation sexuelle ou de
          son handicap ;
        </li>
        <li>conseiller une pratique douteuse ou frauduleuse ;</li>
        <li>comprendre de lien hypertexte ou faire la publicit&eacute; ou la promotion d'une soci&eacute;t&eacute;,
          d'une marque, d'un site, d'un blog ou d'un forum.
        </li>
      </ul>
      <h2>13. Lexique</h2>
      <p><strong>Utilisateur</strong> : Internaute se connectant, utilisant L&rsquo;Application susnomm&eacute;.</p>
      <p><strong>Informations personnelles </strong>: &laquo; les informations qui permettent, sous quelque forme que ce
        soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent &raquo;
        (article 4 de la loi n&deg; 78-17 du 6 janvier 1978).</p>
    </b-card>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import {BCard} from "bootstrap-vue"

export default {
  components: {
    JayaTitle,
    BCard,
  }
}
</script>

<style lang="scss">
.cgu {
  p {
    margin-bottom: 0.5rem;
  }

  h2 {
    color: var(--primary);
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-align: left !important;
  }

  h3 {
    color: var(--secondary);
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    text-align: left !important;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin-left: 2rem;
  }
}
</style>
